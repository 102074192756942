<template>
  <div class="page">
    <oil-header :headerInfo="headerInfo"></oil-header>
    <div class="wrapper">
      <div class="leftFrame">
        <div class="machineSpread">
          <oil-title :tipTitle="oilFiedldName">
            <span slot="tit">{{ oilFiedldSName }}油田</span>
            <span slot="left">设备分布</span>
            <span slot="right">单位：台</span>
          </oil-title>
          <oil-spread-bar-chart
            ref="spreadDevice"
            v-if="flag.spreadDevice"
            :deviceInfo="spreadDeviceInfo"
          ></oil-spread-bar-chart>
        </div>
        <machine-amount :deviceInfo="deviceOnOffInfo"></machine-amount>
        <machine-warning-table
          :alarmInfo="alarmInfo"
          :alarmPx="alarmPx"
        ></machine-warning-table>
      </div>
      <div class="middleFrame">
        <!-- 油田表格 -->
        <div class="oilFiledTable">
          <div class="title">
            <p class="tit">{{ oilFiedldName }}设备分布详细情况</p>
            <div class="backBtn">
              <img :src="backImg" alt="back" />
              <button type="button" @click="$router.back()">返回上一级</button>
            </div>
          </div>
          <oil-filed-table
            :tableData="oilFieldTableData"
            @toSingleMachine="toSingleMachine"
          ></oil-filed-table>
        </div>
        <div class="machineOilProd">
          <div class="left">
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="oilFiedldName"
                placement="top-start"
              >
                <p class="tit">{{ oilFiedldSName }}油田采液量及能耗</p>
              </el-tooltip>
              <p class="unit">采液量单位：立方米<span>能耗单位：kWh</span></p>
            </div>
            <total-oil-prod-chart
              ref="totalDevice"
              v-if="flag.totalDevice"
              :deviceInfo="totalDeviceInfo"
            ></total-oil-prod-chart>
          </div>
          <div class="right">
            <oil-title :tipTitle="oilFiedldName">
              <span slot="tit">{{ oilFiedldSName }}</span>
              <span slot="left">油田7天采液量及能耗</span>
              <span slot="rightwarp">采液量单位：立方米能耗单位：kWh</span>
            </oil-title>
            <history-oil-prod-chart
              ref="historyProdPower"
              v-if="flag.historyProdPower"
              :historyInfo="historyProdPowerInfo"
            ></history-oil-prod-chart>
          </div>
        </div>
      </div>
      <div class="rightFrame">
        <div class="realtimeOilProd">
          <oil-title :tipTitle="oilFiedldName">
            <span slot="tit">{{ oilFiedldSName }}</span>
            <span slot="left">油田实时采液量</span>
            <span slot="right">单位：立方米/小时</span>
          </oil-title>
          <realtime-oil-prod-chart
            ref="realTimeProd"
            v-if="flag.realTimeProd"
            :deviceInfo="realTimeProdInfo"
          ></realtime-oil-prod-chart>
        </div>
        <div class="machineDailyOnlineRate">
          <oil-title :tipTitle="oilFiedldName">
            <span slot="tit">{{ oilFiedldSName }}</span>
            <span slot="left">油田日设备上线率</span>
            <span slot="right">单位：百分比%</span>
          </oil-title>
          <oil-rate-bar-chart
            ref="onlineDevice"
            v-if="flag.onlineDevice"
            :deviceInfo="onlineDeviceInfo"
          ></oil-rate-bar-chart>
        </div>
        <div class="machineDailyOnlineRate">
          <oil-title :tipTitle="oilFiedldName">
            <span slot="tit">{{ oilFiedldSName }}</span>
            <span slot="left">油田设备故障率</span>
            <span slot="right">单位：百分比%</span>
          </oil-title>
          <oil-rate-bar-chart
            ref="faultDevice"
            v-if="flag.faultDevice"
            :deviceInfo="faultDeviceInfo"
          ></oil-rate-bar-chart>
        </div>
        <div class="oilNewsLetter">
          <oil-title :tipTitle="oilFiedldName">
            <span slot="tit">{{ oilFiedldSName }}</span>
            <span slot="left">油田生产简讯</span>
            <span slot="right">实时更新</span>
          </oil-title>
          <oil-newsletter
            v-if="flag.message"
            :messageInfo="messageInfo"
          ></oil-newsletter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OilHeader from 'components/oilHeader/OilHeader.vue'
import OilTitle from 'components/oilTitle/OilTitle.vue'
import OilSpreadBarChart from 'components/oilBarChart/OilSpreadBarChart.vue'
import MachineAmount from 'components/machineAmount/MachineAmount.vue'
import MachineWarningTable from 'components/oilTable/MachineWarningTable.vue'
import TotalOilProdChart from 'components/oilPieChart/TotalOilProdChart.vue'
import HistoryOilProdChart from 'components/oilBarChart/HistoryOilProdChart.vue'
import RealtimeOilProdChart from 'components/oilBarChart/RealtimeOilProdChart.vue'
import OilRateBarChart from 'components/oilBarChart/OilRateBarChart.vue'
import OilNewsletter from 'components/oilNewsletter/OilNewsletter.vue'
import OilFiledTable from 'components/oilTable/oilFiledTable.vue'

import oilFieldJs from 'network/machine/oilField.js'

export default {
  name: 'OilField',
  components: {
    OilHeader,
    OilTitle,
    OilSpreadBarChart,
    MachineAmount,
    MachineWarningTable,
    TotalOilProdChart,
    HistoryOilProdChart,
    RealtimeOilProdChart,
    OilRateBarChart,
    OilNewsletter,
    OilFiledTable
  },
  data: () => {
    return {
      headerInfo: {
        name: '智慧抽油监控系统'
      },
      oilFiedldName: '',
      oilFiedldSName: '',
      backImg: require('../../assets/img/common/back.png'),
      flag: {
        spreadDevice: false,
        historyProdPower: false,
        realTimeProd: false,
        onlineDevice: false,
        faultDevice: false,
        totalDevice: false,
        message: false
      },
      timer: {
        fiveS: null
      },
      spreadDeviceInfo: {},
      alarmInfo: [],
      alarmPx: 0,
      historyProdPowerInfo: {},
      realTimeProdInfo: {},
      onlineDeviceInfo: {},
      faultDeviceInfo: {},
      totalDeviceInfo: {},
      deviceOnOffInfo: {},
      messageInfo: { location: '油田', arr: [] },
      oilFieldTableData: []
    }
  },
  methods: {
    /**
     * 事件监听
     */
    // 跳转到单个设备界面
    toSingleMachine(v) {
      this.$router.push({
        path: '/SingleMachine',
        query: {
          device_name: v.device_name
        }
      })
    },
    // 油田信息初始化
    initOilField() {
      this.oilFiedldName = this.$route.query.name
      this.getAreaOilFieldMap()
      this.getAreaDeviceCount(this.$route.query.id)
      this.getAreaAlarm(this.$route.query.id)
      this.getAreaTotalProd(this.$route.query.id)
      this.getAreaHistoryProdPower(this.$route.query.id)
      this.getAreaDeviceProd(this.$route.query.id)
      this.getAreaDeviceOnline(this.$route.query.id)
      this.getAreaDeviceFault(this.$route.query.id)
      this.getAreaMessage(this.$route.query.id)
      this.getAreaOnline(this.$route.query.id)
    },
    // 定时获取5s
    fiveSecondsTimer() {
      this.timer.fiveS = setInterval(() => {
        this.getAreaAlarm(this.$route.query.id)
      }, 1000 * 5)
    },
    /**
     * 网络请求
     */
    // 获取地区设备分布
    getAreaDeviceCount(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaDeviceCount(params).then((res) => {
        if (res.code == 0) {
          this.spreadDeviceInfo = res.device_info
          this.flag.spreadDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 设备统计
    getAreaOnline(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaOnline(params).then((res) => {
        if (res.code == 0) {
          this.deviceOnOffInfo = {
            total: res.total,
            onlineTotal: res.online_total,
            offLineTotal: res.total - res.online_total,
            onlinePercent: res.online_percent,
            // onlineRate:
            //   res.online_total == 0
            //     ? 0
            //     : (res.online_total / res.total).toFixed(2)
          }
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取故障警报信息
    getAreaAlarm(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaAlarm(params).then((res) => {
        if (res.code == 0) {
          this.alarmInfo = res.alarm_info
          this.alarmPx = res.alarm_info.length * 28
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取年月日采液量及能耗
    getAreaTotalProd(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaTotalProd(params).then((res) => {
        if (res.code == 0) {
          this.totalDeviceInfo = {
            yearInfo: res.year_info,
            monthInfo: res.month_info,
            dayInfo: res.day_info
          }
          this.flag.totalDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区7日采液量及能耗
    getAreaHistoryProdPower(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaHistoryProdPower(params).then((res) => {
        if (res.code == 0) {
          this.historyProdPowerInfo = res.history_info
          this.flag.historyProdPower = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区实时采液量
    getAreaDeviceProd(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaDeviceProd(params).then((res) => {
        if (res.code == 0) {
          this.realTimeProdInfo = res.device_info
          this.flag.realTimeProd = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区日设备上线率
    getAreaDeviceOnline(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaDeviceOnline(params).then((res) => {
        if (res.code == 0) {
          const data = {
            type: '正确率',
            area: res.device_info.area,
            rate: res.device_info.online
          }
          this.onlineDeviceInfo = data
          this.flag.onlineDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区设备故障率
    getAreaDeviceFault(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaDeviceFault(params).then((res) => {
        if (res.code == 0) {
          const data = {
            type: '故障率',
            area: res.device_info.area,
            rate: res.device_info.fault
          }
          this.faultDeviceInfo = data
          this.flag.faultDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取油田设备表格
    getAreaOilFieldMap() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: this.$route.query.id
      }
      oilFieldJs.areaOilFieldMap(params).then((res) => {
        if (res.code == 0) {
          this.oilFieldTableData = res.oilfield_map_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取生产简讯
    getAreaMessage(oilfieldid) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        oilfieldid: oilfieldid
      }
      oilFieldJs.areaMessage(params).then((res) => {
        if (res.code == 0) {
          this.messageInfo.arr = res.message_info
          this.flag.message = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  },
  mounted() {
    this.initOilField()
    // 定时器
    this.fiveSecondsTimer()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  },
  beforeDestroy() {
    window.clearInterval(this.timer.fiveS)
    this.timer.fiveS = null
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.page {
  width: 100%;
  min-height: 100vh;
  min-width: 1200px;
  background: url('~assets/img/common/bg.png');
  background-size: cover;
  .wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
  }
  .leftFrame {
    margin: 19px 0 25px 19px;
    width: 386px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .middleFrame {
    margin: 18px 0 25px 13px;
    width: 1076px;
    height: auto;
    .oilFiledTable {
      margin-bottom: 16px;
      > .title {
        width: 1076px;
        height: 43px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 1px;
        background-image: url('~assets/img/oilField/title-table.png');
        background-size: cover;
        .tit {
          margin-left: 69px;
          width: auto;
          height: 18px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #f9fafa;
          line-height: 11px;
        }
        .backBtn {
          display: flex;
          width: atuo;
          height: auto;
          cursor: pointer;
          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
          button {
            width: auto;
            height: 20px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fff;
            background: transparent;
            border: none;
            &:active {
              color: rgba(87, 156, 243, 0.7);
            }
          }
        }
      }
    }
    .machineOilProd {
      width: 1076px;
      height: auto;
      display: flex;
      justify-content: space-around;
      .left {
        > .title {
          width: 656px;
          height: 43px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          letter-spacing: 1px;
          background: url('~assets/img/common/title2.png');
          background-size: cover;
          .tit {
            margin-left: 69px;
            width: auto;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f9fafa;
            line-height: 11px;
          }
          .unit {
            width: auto;
            height: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(249, 250, 250, 0.64);
            line-height: 11px;
            span {
              margin-left: 20px;
            }
          }
        }
      }
      ::v-deep {
        .historyOilProdChart {
          margin-top: 11px;
          width: 400px;
          height: 303px;
        }
        .oilTitleContainer > .title {
          width: 400px;
                   .rightwarp{
             width: 140px;
           }
        }
      }
    }
  }
  .rightFrame {
    margin: 0 0 25px 14px;
    width: 386px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .machineDailyOnlineRate {
      margin-top: 11px;
    }
    .oilNewsLetter {
      margin-top: 11px;
    }
  }
}
</style>
